<template>
  <sheet dense depressed light outlined v-if="show">
    <template #head>
      <v-toolbar flat>
        <v-toolbar-title v-text="$t('invoice.referenced')" />
      </v-toolbar>
    </template>

    <tf-tree :items="arInvoiceTree" no-padding>
      <template #item="{ item }">
        <invoice-preview-dialog :item="item" />
      </template>
    </tf-tree>
  </sheet>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { Invoice } from "@planetadeleste/vue-mc-gw";
import TfTree from "@/components/common/tree/TfTree.vue";
import InvoicePreviewDialog from "@/modules/invoices/components/InvoicePreviewDialog.vue";
import { get, isEmpty } from "lodash";

@Component({
  components: { InvoicePreviewDialog, TfTree },
})
export default class InvoiceTree extends Vue {
  @Prop([String, Number]) readonly invoiceId!: string;

  arInvoiceTree: InvoiceData[] = [];

  get show() {
    return (
      this.arInvoiceTree.length > 0 &&
      !isEmpty(get(this.arInvoiceTree, "0.children"))
    );
  }

  mounted() {
    const obData = { id: this.invoiceId };
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.tree", obData),
      method: "GET",
      data: obData,
    };
    const obInvoice = new Invoice();
    obInvoice
      .createRequest(obConfig)
      .send()
      .then((response) => {
        if (response.getData()) {
          this.arInvoiceTree = response.getData().data;
        }
      });
  }
}
</script>
