<template>
  <div class="tf-tree" :class="obClass">
    <ul>
      <tf-node v-for="item in items" :key="uid(item.id)" :item="item">
        <template #item="{ item }">
          <slot :item="item" name="item" />
        </template>
        <template #child="{ item: child }">
          <slot :item="child" name="child" />
        </template>
      </tf-node>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import TfNode from "@/components/common/tree/TfNode.vue";
import { uniqueId } from "lodash";

@Component({
  components: { TfNode },
})
export default class TfTree extends Vue {
  @Prop(Array) readonly items!: Record<string, any>[];
  @Prop(Boolean) readonly noPadding!: boolean;
  @Prop(Boolean) readonly dense!: boolean;

  get obClass() {
    return {
      "no-padding": this.noPadding,
      dense: this.dense,
    };
  }

  uid(prefix?: string) {
    return uniqueId(prefix);
  }
}
</script>

<style lang="css">
@import "~treeflex/dist/css/treeflex.css";
</style>
